import jagaimo from "./jagaimo.png"
import satvs from "./satvs.png"
import wiljo from "./wiljo.png"
import avag from "./avag.png"

const projectsMapping = {
    avag,
    jagaimo,
    satvs,
    wiljo
}

export default projectsMapping;