import iconMapping from "../../assets/skills/iconMapping";
import Skill from "../skill/Skill";
import React from "react";
import "./skillCard.css";

const SkillCard = ({ title, icons }) => {
    return (
        <div className="Portafolio__scard">
            <h3 className="Portafolio__scard-title">{title}</h3>
            <div className="Portafolio__scard-grid">
                {icons.map(iconName => (
                    <Skill key={iconName} name={iconName} img={iconMapping[iconName]} />
                ))}
            </div>
        </div>
    );
}

export default SkillCard;
