import { ReactTyped as Typed } from 'react-typed';
import CV from "../../assets/CV_LuisAcevedo.pdf"
import { Button } from "../../components";
import LB from "../../assets/LB.jpg"
import React from 'react';
import "./header.css"

const Header = () => {
    return(
        <div className="Portafolio__header" id="home">
            <div className="Portafolio__header-info">
                <img src={LB} alt="Luiggi"/>
                <div className="Portafolio__header-info_name">
                    <div className="glitch" data-text="Luis Bringas">Luis Bringas</div>
                </div>
                <div className="Portafolio__header-profession">
                    <span className="text">I'm <span className="auto-input pro_text"><Typed strings={["an Engineer", "a PhD Student", "a Programmer", "a Freelancer", "a Web Designer", "a Videogame Developer", "an AI Researcher"]} typeSpeed={100} backSpeed={100} loop /></span></span>
                </div>
                <Button text="Download CV" href={CV}/>
            </div>
        </div>
    );
}

export default Header;
