import { PaperCard } from "../../components";
import React from "react";
import "./publications.css"

const Publications = () => {
    return(
        <div className="Portafolio__Conferences" id="publications">
            <div className="glitch_section" data-text="Research Publications">Research Publications</div>
            <div className="Portafolio__Conferences-cards">
                <PaperCard title="YOLOv5 with Mixed Backbone for Efficient Spatio-Temporal Hand Gesture Localization and Recognition" img="yolo3d" conf="18th International Conference on Machine Vision Applications July 23–25, 2023, Hamamatsu " abstract="Spatio-temporal Hand Gesture Localization and Recognition (SHGLR) refers to analyzing the spatial and temporal aspects of hand movements for detecting and identifying hand gestures in a video. Current state-of-the-art approaches for SHGLR utilize large and complex architectures that result in a high computational cost. To address this issue, we present a new efficient method based on a mixed backbone for YOLOv5. We decided to use it since it is a lightweight and one-stage framework. We designed a mixed backbone that combines 2D and 3D convolutions to obtain temporal information from previous frames. The proposed method offers an efficient way to perform SHGLR on videos by inflating specific convolutions of the backbone while keeping a similar computational cost to the conventional YOLOv5. Due to its challenging and continuous hand gestures, we conduct experiments using the IPN Hand dataset. Our proposed method achieves a frame mAP@0.5 of 66.52% with a 6-frame clip input, outperforming conventional YOLOv5 by 7.89%, demonstrating the effectiveness of our approach." paperurl="https://ieeexplore.ieee.org/document/10215605" confurl="https://www.mva-org.jp/mva2023/" codeurl="https://github.com/LuisEstebanAcevedoBringas/3D-YOLOv5"/>
            </div>
        </div>
    )
}

export default Publications