// Languajes
import csharp from "./languages/csharp.png"
import python from "./languages/python.png"
import cplus from "./languages/cplus.png"
import php from "./languages/php.svg"
import ts from "./languages/ts.png"
import js from "./languages/js.png"
// ia
import seaborn from "./ia/seaborn.png"
import pytorch from "./ia/pytorch.png"
import opencv from "./ia/opencv.png"
import scikit from "./ia/scikit.png"
import tensor from "./ia/tensor.png"
import pandas from "./ia/pandas.png"
// learning
import tailwind from "./learning/tailwindcss.svg"
import unreal from "./learning/unreal.svg"
import spring from "./learning/spring.svg"
import ruby from "./learning/ruby.svg"
import vue from "./learning/vue.svg"
// software
import photoshop from "./software/photoshop.png"
import premier from "./software/premier.png"
import figma from "./software/figma.png"
import canva from "./software/canva.png"
import xd from "./software/xd.png"
// tools
import wordpress from "./tools/wordpress.svg"
import overleaf from "./tools/overleaf.svg"
import postman from "./tools/postman.png"
import docker from "./tools/docker.svg"
import visual from "./tools/visual.svg"
import github from "./tools/github.png"
import gitlab from "./tools/gitlab.svg"
import linux from "./tools/linux.svg"
import slack from "./tools/slack.svg"
import bash from "./tools/bash.svg"
import git from "./tools/git.svg"
import npm from "./tools/npm.svg"
// web
import bootstrap from "./web/bootstrap.svg"
import firebase from "./web/firebase.svg"
import angular from "./web/angular.svg"
import heroku from "./web/heroku.svg"
import nodejs from "./web/nodejs.svg"
import jquery from "./web/jquery.svg"
import flask from "./web/flask.svg"
import mongo from "./web/mongo.svg"
import react from "./web/react.svg"
import mysql from "./web/mysql.svg"
import html from "./web/html.svg"
import sass from "./web/sass.svg"
import css from "./web/css.svg"
import qt from "./web/qt.png"
import dj from "./web/dj.svg"

const iconMapping = {
    csharp,
    python,
    cplus,
    js,
    ts,
    php,
    angular,
    bootstrap,
    css,
    dj,
    firebase,
    flask,
    heroku,
    html,
    mongo,
    mysql,
    nodejs,
    qt,
    react,
    sass,
    jquery,
    opencv,
    pandas,
    pytorch,
    scikit,
    seaborn,
    tensor,
    canva,
    figma,
    photoshop,
    premier,
    xd,
    bash,
    docker,
    git,
    github,
    gitlab,
    linux,
    npm,
    overleaf,
    postman,
    slack,
    visual,
    wordpress,
    ruby,
    spring,
    vue,
    unreal,
    tailwind
};

export default iconMapping;
