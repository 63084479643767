import {SkillCard} from "../../components"
import React from "react";
import "./skills.css"

const Skills = () => {

    const toolsIcons = ["git", "github", "gitlab", "linux", "bash", "npm", "overleaf", "postman", "visual"]
    const aiIcons = ["pytorch", "opencv", "pandas", "scikit", "seaborn", "tensor"]
    const learningIcons = ["ruby", "spring", "vue", "docker", "unreal","tailwind"]
    const frontIcons = ["react", "angular", "bootstrap", "html", "css", "sass"]
    const languagesIcons = ["js", "ts", "python", "cplus", "csharp", "php"]
    const softwareIcons = ["photoshop", "premier", "xd", "canva", "figma"]
    const databasesIcons = ["mysql", "mongo", "firebase"]
    const backIcons = ["nodejs", "dj", "flask"]

    return(
        <div className="Portafolio__Skills" id="skills">
            <div className="glitch_section" data-text="Skill Set">Skill Set</div>
            <div className="Portafolio__Skills-cards">
                <SkillCard title="Languages" icons={languagesIcons}/>
                <SkillCard title="Frontend" icons={frontIcons}/>
                <SkillCard title="Backend" icons={backIcons}/>
                <SkillCard title="Databases" icons={databasesIcons}/>
                <SkillCard title="AI" icons={aiIcons}/>
                <SkillCard title="Software" icons={softwareIcons}/>
                <SkillCard title="Tools" icons={toolsIcons}/>
                <SkillCard title="Learning" icons={learningIcons}/>
            </div>
            
        </div>
    )
}

export default Skills