import iconsMapping from "../../assets/icons/iconsMapping";
import React from "react";
import "./social.css";

const Social = ({ name, url }) => {
    const iconUrl = iconsMapping[name];
    return (
        <a className="Portafolio__Social-btn" href={url} target="_blank" rel="noopener noreferrer">
            <img src={iconUrl} alt={name} />
        </a>
    );
};

export default Social;
