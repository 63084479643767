import instagram from "./instagram.svg"
import linkedin from "./linkedin.svg"
import github from "./github.svg"
import close from "./close.svg"
import menu from "./menu.svg"

const iconsMapping = {
    instagram,
    linkedin,
    github,
    close,
    menu
}

export default iconsMapping;